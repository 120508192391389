import React, { useState } from 'react';
import { ModalWrapper } from '../components/Modal/ModalWrapper/ModalWrapper';

export const ModalContext = React.createContext({
  onOpenModal: () => {},
  onCloseModal: () => {},
  openModal: {},
});

export function useModalContext() {
  const modalContext = React.useContext(ModalContext);

  if (!modalContext) {
    throw new Error(
      'useModalContext must be used within a ModalContextProvider',
    );
  }
  return modalContext;
}

export const ModalProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState({ open: false });

  const onOpenModal = (name = 'ConditionModal', content) => {
    setOpenModal({ name, content, open: true });
  };

  const onCloseModal = () => {
    setOpenModal({ open: false });
  };

  const value = {
    openModal,
    onOpenModal,
    onCloseModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      <ModalWrapper />
    </ModalContext.Provider>
  );
};
