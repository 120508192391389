import { getApplicationTransportLabel } from './getApplicationTransportLabel';
import { getApplicationTypeLabel } from './getApplicationTypeLabel';
import { getDefaultDateFormat } from './getDefaultDateFormat';

export const getApplicationFieldValue = (fieldId, application) => {
  const filedValue = application[fieldId];

  console.log(filedValue);
  switch (fieldId) {
    case 'type':
      return getApplicationTypeLabel(filedValue);
    case 'country':
    case 'allowedHotelsRating':
      return filedValue.join(', ');
    case 'createdAt':
      return getDefaultDateFormat(+filedValue);
    case 'dateFrom':
    case 'dateTo':
      return getDefaultDateFormat(filedValue);
    case 'childrenAges':
      return `${
        filedValue.length
          ? `${filedValue.length} (${filedValue.join(', ')} лет)`
          : 'нет'
      }`;
    case 'transportType':
      return getApplicationTransportLabel(filedValue);
    case 'isBusinessClass':
      return filedValue ? 'Да' : 'Нет';
    default:
      return filedValue;
  }
};
