import { TableCell, TableHead, TableRow } from '@mui/material';

const TableListHeader = ({ headCells }) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((column) => (
          <TableCell
            key={column.id}
            align={column.align}
            style={{ minWidth: column.minWidth, maxWidth: 200 }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableListHeader;
