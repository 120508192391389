import { IconButton, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Wrapper } from './BackBtn.styled';
import { useNavigate } from 'react-router-dom';

export const BackBtn = ({ sx }) => {
  const navigate = useNavigate();

  return (
    <Wrapper sx={sx}>
      <IconButton
        onClick={() => {
          navigate(-1);
        }}
        color='primary'
        size='small'
        aria-label='back btn'
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <Typography component={'span'}>Вернуться назад</Typography>
    </Wrapper>
  );
};
