import { Typography } from '@mui/material';
import { Wrapper, Title } from './DetailRow.styled';

export const DetailRow = ({ sx, title, description }) => {
  return (
    <Wrapper sx={sx}>
      <Title>{`${title}:`}</Title>
      <Typography>{description}</Typography>
    </Wrapper>
  );
};
