import { LoadingButton } from '@mui/lab';
import { Box, styled } from '@mui/material';

export const Wrap = styled(Box)({
  width: '100%',
  maxWidth: 500,
});
export const SubmitBtn = styled(LoadingButton)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
