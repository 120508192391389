import React, { useState } from 'react';

export const AlertContext = React.createContext({
  open: false,
  content: {
    title: '',
    subTitle: '',
    error: false,
  },
  onOpenAlert: () => {},
  onCloseAlert: () => {},
});

export function useAlertContext() {
  const alertContext = React.useContext(AlertContext);

  if (!alertContext) {
    throw new Error('useAlertContext must be used within a AlertProvider');
  }
  return alertContext;
}

export const AlertProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState({
    title: '',
    subTitle: '',
    error: false,
  });

  const onOpenAlert = (values) => {
    setOpen(true);
    setContent(values);
  };

  const onCloseAlert = () => {
    setOpen(false);
  };

  const value = {
    open,
    content,
    onOpenAlert,
    onCloseAlert,
  };

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};
