import * as Yup from 'yup';

export const loginSchemas = Yup.object({
  password: Yup.string().required('Заполните данное поле'),
});
export const addCountrySchemas = Yup.object({
  title: Yup.string().required('Заполните данное поле'),
  shortDescription: Yup.string().required('Заполните данное поле'),
  description: Yup.string().required('Заполните данное поле'),
  image: Yup.mixed().required('Заполните данное поле'),
});
