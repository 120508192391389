import { useLazyQuery, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  APPLICATIONS_QUERY,
  APPLICATION_QUERY,
  COUNTRIES_QUERY,
  COUNTRY_QUERY,
  SIGN_IN_QUERY,
} from '../graphql/queries';
import { useAuthContext } from '../context';

//Auth
export const useSignInQuery = () => {
  const { onSuccessAuth } = useAuthContext();
  return useLazyQuery(SIGN_IN_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      console.log(response);
      if (response?.signIn) onSuccessAuth(response.signIn);
    },
    onError: () => {
      toast('Вы ввели неверный логин или пароль', {
        type: 'error',
      });
    },
  });
};

//Applications
export const useApplicationsQuery = () => {
  return useLazyQuery(APPLICATIONS_QUERY, {
    fetchPolicy: 'network-only',
  });
};

export const useApplicationQuery = (options) => {
  return useQuery(APPLICATION_QUERY, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

//Countries
export const useCountriesQuery = () => {
  return useLazyQuery(COUNTRIES_QUERY, {
    fetchPolicy: 'network-only',
  });
};

export const useCountryQuery = (options) => {
  return useQuery(COUNTRY_QUERY, {
    fetchPolicy: 'network-only',
    ...options,
  });
};
