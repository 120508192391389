import React from 'react';

export const ImagePlaceholderDropzone = () => {
  return (
    <svg
      width='64'
      height='56'
      viewBox='0 0 64 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.3679 55.9282H49.3439C66.291 54.5436 64.8487 37.2359 61.6036 32.3898L52.5892 18.1975C48.2622 13.6974 44.4401 12.659 36.3632 23.7359C28.2863 34.8129 23.3826 32.3898 17.9738 25.8129C12.9257 21.3128 4.27176 22.3513 0.665988 35.5052C-2.65511 47.6205 7.15643 55.9282 14.3679 55.9282Z'
        fill='#9A9AB0'
        fillOpacity='0.55'
      />
      <ellipse
        cx='10.6875'
        cy='4.5'
        rx='4.6875'
        ry='4.5'
        fill='#9A9AB0'
        fillOpacity='0.55'
      />
    </svg>
  );
};
