import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { TABLE_LIMITS_ARRAY_OPTIONS } from '../../constants/constants';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Spinner from '../Spinner/Spinner';

import TableListHeader from './TableListHeader/TableListHeader';
import TableListRow from './TableListRow/TableListRow';
import { AddBtn, AddBtnIcon, TitleWrapper } from './TableList.styled';
import { TableToolBar } from './TableToolBar/TableToolBar';
import { useNavigate } from 'react-router-dom';

const TableList = ({
  name,
  isLoading,
  count,
  limit,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  headCells,
  rows,
  rowAction,
  hasFilter = false,
  buttonAction,
  buttonLoading,
  actionButtonTitle = 'Присоедениться',
  isHasPagination = true,
  isChildren,
  handleCreateOpen,
  handleDelete,
  handleEdit,
  row,

  handleOpen,
  isHasBackBtn,
}) => {
  const navigate = useNavigate();

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TitleWrapper>
        {isHasBackBtn && (
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            color='primary'
            size='small'
            aria-label='back btn'
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        )}
        {name && <TableToolBar listName={name} />}
      </TitleWrapper>

      {handleCreateOpen && (
        <AddBtn
          onClick={() => {
            handleCreateOpen(row);
          }}
          color={'primary'}
          size='medium'
          aria-label='add item'
        >
          <AddBtnIcon />
        </AddBtn>
      )}

      <TableContainer
        sx={{
          height: isChildren
            ? 'auto'
            : `calc(100vh - 250px - ${hasFilter ? '90px' : '0px'})`,
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <Table stickyHeader aria-label='sticky table'>
            <TableListHeader headCells={headCells} />
            <TableBody>
              {rows?.map((row, index) => {
                return (
                  <TableListRow
                    key={index}
                    row={row}
                    rowAction={rowAction}
                    headCells={headCells}
                    buttonAction={buttonAction}
                    buttonLoading={buttonLoading}
                    actionButtonTitle={actionButtonTitle}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    handleCreateOpen={handleCreateOpen}
                    handleOpen={handleOpen}
                  />
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {isHasPagination && !isLoading && (
        <TablePagination
          rowsPerPageOptions={TABLE_LIMITS_ARRAY_OPTIONS}
          component='div'
          count={count}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default TableList;
