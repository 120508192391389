import { styled } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import palette from '../../theme/palette';

export const CustomToastContainer = styled(ToastContainer)(() => {
  return {
    width: '100%',
    bottom: 0,
    textAlign: 'center',
    '& > div': {
      marginBottom: 2,
      borderRadius: 0,
    },
    '& .Toastify__toast--success': {
      background: palette.correct,
      color: palette.white,
    },
    '& .Toastify__toast--error': {
      background: palette.error.main,
      color: palette.white,
    },
    '& .Toastify__toast--warning': {
      background: palette.attention2,
    },
    '& .Toastify__toast--info': {
      background: palette.info,
    },
  };
});
