import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableList from '../../components/TableList/TableList';
import {
  APPLICATION_TYPES,
  APPLICATION_TYPES_OPTIONS,
  MODAL_NAME,
  PATHS,
  SIMPLE_APPLICATION_OPTIONS,
} from '../../constants/constants';

import { Wrap } from './Applications.styled';
import { useModalContext } from '../../context';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {
  useApplicationsQuery,
  useRemoveApplicationMutation,
} from '../../hooks';
import { getApplicationTypeLabel, getDefaultDateFormat } from '../../utils';
import { toast } from 'react-toastify';

const createData = (application) => {
  const idNumber = `#${application.id}`;
  const countries = application.country.join(', ');
  const type = getApplicationTypeLabel(application.type);

  const date = getDefaultDateFormat(+application.createdAt);

  return { ...application, idNumber, countries, date, type };
};

export const Applications = () => {
  const { onOpenModal } = useModalContext();
  const navigate = useNavigate();

  const [applicationsQuery, { loading, data }] = useApplicationsQuery();
  const [removeApplicationMutation] = useRemoveApplicationMutation({
    onCompleted: () => {
      toast('Заявка успешно удалена', {
        type: 'success',
      });
      getApplications();
    },
  });

  const [filterBy, setFilterBy] = useState(APPLICATION_TYPES.ALL);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const handleRemove = (id) => {
    removeApplicationMutation({
      variables: {
        id,
      },
    });
  };

  const getApplications = () => {
    const typesParams =
      filterBy === APPLICATION_TYPES.ALL ? {} : { types: [filterBy] };
    applicationsQuery({
      variables: {
        options: {
          limit,
          offset: page * limit,
          sort: {
            by: 'CREATED_AT',
            direction: 'DESC',
          },
          ...typesParams,
        },
      },
    });
  };

  const rows =
    data?.applications?.rows?.map((application) => createData(application)) ||
    [];
  const count = data?.applications?.count || 0;

  useEffect(() => {
    getApplications();
  }, [limit, page]);

  useEffect(() => {
    getApplications();
  }, [filterBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(+event.target.value);
  };

  const handleChange = (event) => {
    setPage(0);
    setFilterBy(event.target.value);
  };

  const handleDelete = (row) => {
    onOpenModal(MODAL_NAME.conditionModal, {
      id: row?.id,
      handelSubmit: () => handleRemove(row.id),
      title: `Удалить заявку ${row.idNumber}?`,
    });
  };

  const handleOpen = (row) => {
    navigate(`${PATHS.applications}/${row.id}`);
  };

  return (
    <Wrap>
      <Box sx={{ width: 300, marginLeft: 'auto' }}>
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>Сортировать по</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={filterBy}
            label='Фильтровать по'
            onChange={handleChange}
          >
            {APPLICATION_TYPES_OPTIONS?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item?.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <TableList
        name={'Список заявок'}
        isLoading={loading}
        count={count}
        limit={limit}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        headCells={SIMPLE_APPLICATION_OPTIONS}
        rows={rows}
        hasFilter
        handleDelete={handleDelete}
        handleOpen={handleOpen}
      />
    </Wrap>
  );
};
