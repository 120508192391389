import { Box, Typography } from '@mui/material';
import { loginSchemas } from '../../services/validation/schemas';
import { Formik } from 'formik';
import { ButtonWrapper, FormWrapper, SubmitBtn, Wrapper } from './styles';
import fontSizes from '../../theme/fontSizes';
import fontWeights from '../../theme/fontWeights';
import { TextFieldFormik } from '../../components';
import { useSignInQuery } from './../../hooks';

export const Login = () => {
  const [singInQuery, { loading }] = useSignInQuery();

  const initialValues = {
    password: '',
  };

  const handleSubmit = (values) => {
    singInQuery({
      variables: {
        input: {
          password: values.password,
        },
      },
    });
  };

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={loginSchemas}
      >
        {({ handleSubmit }) => (
          <FormWrapper>
            <Typography
              align='center'
              component='h2'
              mb={'20px'}
              fontSize={fontSizes.ml}
              fontWeight={fontWeights.BOLD}
            >
              Авторизация
            </Typography>
            <Box>
              <TextFieldFormik
                name='password'
                placeholder={'Password'}
                type='text'
                label={'Password *'}
                sx={{ marginBottom: '10px' }}
              />
            </Box>

            <ButtonWrapper>
              <SubmitBtn
                loading={loading}
                variant='contained'
                size='large'
                color='primary'
                onClick={handleSubmit}
              >
                {'Войти'}
              </SubmitBtn>
            </ButtonWrapper>
          </FormWrapper>
        )}
      </Formik>
    </Wrapper>
  );
};
