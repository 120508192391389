import { TRANSPORT_TYPES } from '../constants/constants';

export const getApplicationTransportLabel = (type) => {
  switch (type) {
    case TRANSPORT_TYPES.BUS:
      return 'Автобус';
    case TRANSPORT_TYPES.BUS_AND_FLIGHT:
      return 'Автобус и самолёт';
    case TRANSPORT_TYPES.FLIGHT:
      return 'Самолёт';
    default:
      return 'Другой тип';
  }
};
