import { Box, TextField, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';

import { LabelErrorWrapper } from './styles';
import palette from '../../theme/palette';

export const TextFieldFormik = ({
  name = '',
  label = '',
  placeholder = '',
  fullWidth = true,
  multiline = false,
  minRows,
  sx,
  type = 'text',
}) => {
  const [field, meta] = useField(name);
  const { handleChange } = useFormikContext();

  const hasError = Boolean(meta.error && meta.touched);

  return (
    <Box sx={sx}>
      {label || hasError ? (
        <LabelErrorWrapper>
          {label ? (
            <label htmlFor={name}>
              <Typography variant='body1' color={palette.input.label}>
                {label}
              </Typography>
            </label>
          ) : null}
          {hasError ? (
            <Typography ml={'auto'} variant='error'>
              {meta.error}
            </Typography>
          ) : null}
        </LabelErrorWrapper>
      ) : null}

      <TextField
        id={name}
        name={name}
        value={field.value}
        onChange={handleChange}
        error={hasError}
        placeholder={placeholder}
        fullWidth={fullWidth}
        multiline={multiline}
        minRows={minRows}
        type={type}
      />
    </Box>
  );
};
