import { Box, styled } from '@mui/material';
import fontWeights from '../../theme/fontWeights';

export const Wrapper = styled(Box)({
  display: 'flex',
  marginBottom: 10,
});
export const Title = styled(Box)({
  fontWeight: fontWeights.BOLD,
  marginRight: 5,
});
