import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider } from '@mui/material';
import { AuthProvider, AlertProvider, ModalProvider } from './context';
import { Navigation } from './navigations/Navigations';
import { theme } from './theme/theme';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client';
import TooltipContainer from './components/TooltipContainer/TooltipContainer';

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <ModalProvider>
          <AlertProvider>
            <AuthProvider>
              <Navigation />
              <TooltipContainer />
            </AuthProvider>
          </AlertProvider>
        </ModalProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
