import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import { PATHS, ROUTES } from '../constants/constants';

import { useAuthContext } from '../context';
import { MainLayout } from '../components/Layouts/index';
import { NotFound } from '../screens';

const RouteWrapper = ({ children, authRequired }) => {
  const location = useLocation();
  const { isAuth } = useAuthContext();

  const redirectToLogin =
    (!isAuth && authRequired) || (!isAuth && location.pathname !== PATHS.login);

  if (redirectToLogin) {
    return <Navigate replace to={PATHS.login} />;
  } else if (!authRequired && isAuth) {
    return <Navigate replace to={PATHS.applications} />;
  }

  return <>{authRequired ? <MainLayout>{children}</MainLayout> : children}</>;
};

export const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        {ROUTES.map((route) => {
          const { path, component: Component, auth } = route;

          return (
            <Route
              key={path}
              path={path}
              element={
                <RouteWrapper authRequired={auth} path={path}>
                  <Component />
                </RouteWrapper>
              }
            />
          );
        })}
        <Route key={'NotFound'} path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
