import { IconButton, TableCell, TableRow } from '@mui/material';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { TABLE_COLUMN_VARIANTS } from '../../../constants/constants';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const TableListRow = ({
  row,
  rowAction,
  headCells,
  handleDelete,
  handleEdit,

  handleOpen,
}) => {
  return (
    <>
      <TableRow
        hover={rowAction ? true : false}
        role='checkbox'
        tabIndex={-1}
        key={row.code}
        sx={{ cursor: rowAction ? 'pointer' : 'auto' }}
        onClick={() => {
          rowAction && rowAction(row?.id);
        }}
      >
        {headCells.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} style={{ maxWidth: 100 }}>
              {column.id === TABLE_COLUMN_VARIANTS.control ? (
                <>
                  {handleDelete && (
                    <IconButton
                      onClick={() => {
                        handleDelete(row);
                      }}
                      color='secondary'
                      size='small'
                      aria-label='delete item'
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  )}
                  {handleEdit && (
                    <IconButton
                      onClick={() => {
                        handleEdit(row);
                      }}
                      color='primary'
                      size='small'
                      aria-label='edit item'
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  {handleOpen && (
                    <IconButton
                      onClick={() => {
                        handleOpen(row);
                      }}
                      color='primary'
                      size='small'
                      aria-label='edit item'
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  )}
                </>
              ) : column.id === TABLE_COLUMN_VARIANTS.image ? (
                <>
                  <img
                    // eslint-disable-next-line no-undef
                    src={`${process.env.REACT_APP_FILES_PATH}/${value}`}
                    alt='row-img'
                    width={100}
                  />
                </>
              ) : (
                value
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};

export default TableListRow;
