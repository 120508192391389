import { Box, Typography } from '@mui/material';

import Spinner from '../Spinner/Spinner';

export const RequestHandler = ({
  loading,
  error,
  children,
  height = '50vh',
}) => {
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: height,
          width: 'auto',
        }}
      >
        <Spinner />
      </Box>
    );
  }

  if (error) {
    return <Typography variant='h2'>{error.message}</Typography>;
  }
  return <>{children}</>;
};
