import React, { useEffect, useState } from 'react';
import { client } from '../graphql/client';
import { RequestHandler } from '../components';

export const AuthContext = React.createContext({
  isAuth: false,
  onSuccessAuth: () => {},
  onLogout: () => {},
});

export function useAuthContext() {
  const authContext = React.useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return authContext;
}

export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const handleSuccessAuth = (token) => {
    localStorage.setItem('token', token);
    setIsAuth(true);
  };

  const handleLogout = () => {
    setIsAuth(false);
    localStorage.removeItem('token');
    client.clearStore();
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      setIsAuth(true);
    }
    setIsInit(true);
  }, []);

  const value = {
    isAuth,
    onSuccessAuth: handleSuccessAuth,
    onLogout: handleLogout,
  };

  return (
    <AuthContext.Provider value={value}>
      <RequestHandler loading={!isInit}>{children}</RequestHandler>
    </AuthContext.Provider>
  );
};
