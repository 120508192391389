import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import PublicIcon from '@mui/icons-material/Public';

import { Applications, Countries, Country, Login } from '../screens';
import { Application } from '../screens/Application/Application';

export const PATHS = {
  applications: '/applications',
  application: '/applications/:id',
  countries: '/countries',
  addCountry: '/country-add',
  editCountry: '/countries/:id',
  login: '/',
};

export const ROUTES = [
  {
    path: PATHS.login,
    component: Login,
    auth: false,
  },
  {
    path: PATHS.applications,
    component: Applications,
    auth: true,
  },
  {
    path: PATHS.application,
    component: Application,
    auth: true,
  },
  {
    path: PATHS.countries,
    component: Countries,
    auth: true,
  },
  {
    path: PATHS.addCountry,
    component: Country,
    auth: true,
  },
  {
    path: PATHS.editCountry,
    component: Country,
    auth: true,
  },
];

export const NAVIGATIONS = [
  {
    title: 'Заявки',
    route: PATHS.applications,
    Icon: AppsOutageIcon,
  },
  {
    title: 'Страны',
    route: PATHS.countries,
    Icon: PublicIcon,
  },
];

// tables constants
export const TABLE_LIMITS_ARRAY_OPTIONS = [5, 10, 15];

//new
export const SIMPLE_APPLICATION_OPTIONS = [
  { id: 'idNumber', label: 'Номер заявки', minWidth: 100 },
  { id: 'name', label: 'Имя', minWidth: 100 },
  { id: 'phone', label: 'Телефон', minWidth: 100 },
  { id: 'messenger', label: 'Месенджер', minWidth: 100 },
  { id: 'country', label: 'Страна / Страны', minWidth: 100 },
  { id: 'date', label: 'Дата', minWidth: 100 },
  { id: 'type', label: 'Тип заявки', minWidth: 100 },
  { id: 'control', label: 'Контроль', minWidth: 100 },
];

export const APPLICATION_FIELD_OPTIONS = [
  { id: 'id', label: 'Номер заявки' }, //+
  { id: 'type', label: 'Тип заявки' }, //+
  { id: 'name', label: 'Имя' }, // +
  { id: 'phone', label: 'Телефон' }, //+
  { id: 'country', label: 'Страны' }, //+
  { id: 'messenger', label: 'Контактный месенджер' }, //+
  { id: 'createdAt', label: 'Дата заявки' }, //+
  { id: 'dateFrom', label: 'Дата с' }, //+
  { id: 'dateTo', label: 'Дата по' }, //+
  { id: 'nightsFromCount', label: 'Количество ночей с' }, //+
  { id: 'nightsToCount', label: 'Количество ночей по' }, //+
  { id: 'adultsCount', label: 'Количество взрослых' }, //+
  { id: 'childrenAges', label: 'Количество детей' }, //+
  { id: 'transportType', label: 'Транспорт' }, //+
  { id: 'allowedHotelsRating', label: 'Категории отелей' }, //+
  { id: 'nutrition', label: 'Тип питания' }, //+
  { id: 'budget', label: 'Бюджет' }, //+
  { id: 'isBusinessClass', label: 'Бизнес клас' },
  { id: 'comment', label: 'Коментарий' },
];

export const COUNTRIES_OPTIONS = [
  { id: 'title', label: 'Назва', minWidth: 100 },
  { id: 'image', label: 'Зображення', minWidth: 100 },
  { id: 'shortDescription', label: 'Короткий опис', minWidth: 100 },
  // { id: 'description', label: 'Подробное описание', minWidth: 100 },
  { id: 'control', label: 'Контроль', minWidth: 100 },
];

export const APPLICATION_TYPES = {
  COUNTRY: 'COUNTRY',
  EXTENDED: 'EXTENDED',
  FLIGHTS: 'FLIGHTS',
  HOT_TOUR: 'HOT_TOUR',
  SIMPLE: 'SIMPLE',
  VISA: 'VISA',
  ALL: 'ALL',
};

export const TRANSPORT_TYPES = {
  BUS: 'BUS',
  BUS_AND_FLIGHT: 'BUS_AND_FLIGHT',
  FLIGHT: 'FLIGHT',
};

export const APPLICATION_TYPES_OPTIONS = [
  { value: APPLICATION_TYPES.ALL, label: 'Все' },
  { value: APPLICATION_TYPES.SIMPLE, label: 'Простые заявки (главная)' },
  { value: APPLICATION_TYPES.EXTENDED, label: 'Расширенные заявки (главная)' },
  { value: APPLICATION_TYPES.COUNTRY, label: 'Страны' },
  { value: APPLICATION_TYPES.FLIGHTS, label: 'Авиабилеты' },
  { value: APPLICATION_TYPES.HOT_TOUR, label: 'Горящие туры' },
  { value: APPLICATION_TYPES.VISA, label: 'Визы' },
];

export const DEFAULT_DROPZONE_OPTIONS = {
  maxSize: 15000000,
  multiple: false,
  accept: {
    'image/jpeg': [],
    'image/png': [],
  },
};

export const MODAL_NAME = {
  conditionModal: 'ConditionModal',
};

export const TABLE_COLUMN_VARIANTS = {
  control: 'control',
  image: 'image',
};
