import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useModalContext } from '../../../context';
import { MODAL_NAME } from '../../../constants/constants';

import { ConditionModal } from '../index';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#fff',
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
  overflow: 'auto',
  maxHeight: 600,
  display: 'block',
};

export const ModalWrapper = () => {
  const { openModal, onCloseModal } = useModalContext();

  return (
    <Modal
      open={openModal.open}
      onClose={() => onCloseModal()}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <TypeModal />
      </Box>
    </Modal>
  );
};

const TypeModal = () => {
  const { openModal } = useModalContext();

  switch (openModal.name) {
    case MODAL_NAME.conditionModal:
      return <ConditionModal />;

    default:
      return <Box />;
  }
};
