import { useModalContext } from '../../../context';
import { MODAL_NAME } from '../../../constants/constants';
import { BtnWrap } from './ConditionModal.styled';
import Button from '@mui/material/Button';
import * as React from 'react';
import { ModalHead } from '../ModalHead/ModalHead';

export const ConditionModal = () => {
  const { onCloseModal, openModal } = useModalContext();

  const handleYesClick = () => {
    openModal.content?.handelSubmit?.();
    handleClose();
  };

  const handleClose = () => {
    onCloseModal(MODAL_NAME.conditionModal);
  };

  return (
    <>
      <ModalHead title={openModal.content.title} />
      <BtnWrap>
        <Button fullWidth variant={'contained'} onClick={handleYesClick}>
          Да
        </Button>
        <Button fullWidth variant={'contained'} onClick={handleClose}>
          Нет
        </Button>
      </BtnWrap>
    </>
  );
};
