import { gql } from '@apollo/client';

export const SIGN_IN_QUERY = gql`
  query signIn($input: SignInInput!) {
    signIn(input: $input)
  }
`;

export const APPLICATIONS_QUERY = gql`
  query applications($options: GetApplicationsOptionsInput!) {
    applications(options: $options) {
      rows {
        adultsCount
        allowedHotelsRating
        budget
        childrenAges
        comment
        country
        countryId
        createdAt
        dateFrom
        dateTo
        id
        isBusinessClass
        messenger
        name
        nightsFromCount
        nightsToCount
        nutrition
        phone
        transportType
        type
      }
      count
    }
  }
`;

export const APPLICATION_QUERY = gql`
  query application($id: Int!) {
    application(id: $id) {
      adultsCount
      allowedHotelsRating
      budget
      childrenAges
      comment
      country
      countryId
      createdAt
      dateFrom
      dateTo
      id
      isBusinessClass
      messenger
      name
      nightsFromCount
      nightsToCount
      nutrition
      phone
      transportType
      type
    }
  }
`;

export const COUNTRIES_QUERY = gql`
  query countries($options: PaginationInput!) {
    countries(options: $options) {
      rows {
        description
        id
        imagePath
        shortDescription
        title
      }
      count
    }
  }
`;

export const COUNTRY_QUERY = gql`
  query country($id: Int!) {
    country(id: $id) {
      description
      id
      imagePath
      shortDescription
      title
    }
  }
`;
