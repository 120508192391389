import { APPLICATION_TYPES } from '../constants/constants';

export const getApplicationTypeLabel = (type) => {
  switch (type) {
    case APPLICATION_TYPES.COUNTRY:
      return 'Странa';
    case APPLICATION_TYPES.SIMPLE:
      return 'Простая';
    case APPLICATION_TYPES.EXTENDED:
      return 'Расширенная';
    case APPLICATION_TYPES.FLIGHTS:
      return 'Авиабилеты';
    case APPLICATION_TYPES.HOT_TOUR:
      return 'Горящий тур';
    case APPLICATION_TYPES.VISA:
      return 'Виза';
    default:
      return 'Другой тип';
  }
};
