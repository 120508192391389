import { Form, Formik } from 'formik';
import { SubmitBtn, Wrap } from './Country.styled';
import { Box, Typography } from '@mui/material';
import {
  BackBtn,
  DropzoneFormik,
  RequestHandler,
  TextFieldFormik,
} from '../../components';
import fontSizes from '../../theme/fontSizes';
import fontWeights from '../../theme/fontWeights';
import {
  useCountryQuery,
  useCreateCountryMutation,
  useUpdateCountryMutation,
} from '../../hooks';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { addCountrySchemas } from '../../services/validation/schemas';

export const Country = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: countryData,
    error,
    loading,
  } = useCountryQuery({
    variables: {
      id: +id,
    },
    skip: !id,
  });

  console.log({ countryData });

  const [createCountryMutation] = useCreateCountryMutation({
    onCompleted: () => {
      toast('Страна успешно добавлена', {
        type: 'success',
      });
      navigate(-1);
    },
  });
  const [updateCountryMutation] = useUpdateCountryMutation({
    onCompleted: () => {
      toast('Страна успешно отредактирована', {
        type: 'success',
      });
      navigate(-1);
    },
  });

  const handleSubmit = (values) => {
    const mutation = id ? updateCountryMutation : createCountryMutation;
    const idParams = id ? { id: +id } : {};

    mutation({
      variables: {
        input: {
          ...values,
          ...idParams,
          image: typeof values.image === 'string' ? null : values.image,
        },
      },
    });
  };

  return (
    <RequestHandler error={error} loading={loading}>
      <Wrap>
        <BackBtn sx={{ marginBottom: '30px' }} />
        <Formik
          initialValues={{
            title: countryData?.country?.title || '',
            image:
              // eslint-disable-next-line no-undef
              `${process.env.REACT_APP_FILES_PATH}/${countryData?.country?.imagePath}` ||
              null,
            shortDescription: countryData?.country?.shortDescription || '',
            description: countryData?.country?.description || '',
          }}
          validationSchema={addCountrySchemas}
          onSubmit={handleSubmit}
        >
          {() => {
            return (
              <Form>
                <Box>
                  <Typography
                    component='h2'
                    mb={'20px'}
                    fontSize={fontSizes.ml}
                    fontWeight={fontWeights.BOLD}
                  >
                    {`${id ? 'Редактирование' : 'Добавление'} страны`}
                  </Typography>
                  <TextFieldFormik
                    name='title'
                    placeholder={'Введите название'}
                    type='text'
                    label={'Название страны'}
                    sx={{ marginBottom: '10px' }}
                  />
                  <TextFieldFormik
                    name='shortDescription'
                    placeholder={'Введите краткое описание'}
                    type='text'
                    label={'Краткое описание'}
                    sx={{ marginBottom: '10px' }}
                  />
                  <TextFieldFormik
                    name='description'
                    placeholder={'Введите полное описание'}
                    type='text'
                    label={'Полное описание'}
                    multiline
                    minRows={5}
                    sx={{ marginBottom: '10px' }}
                  />
                  <DropzoneFormik
                    name={'image'}
                    label={'Картинка страны'}
                    subLabel={''}
                    mb={'24px'}
                  />

                  <SubmitBtn
                    loading={false}
                    variant='contained'
                    size='large'
                    color='primary'
                    type='submit'
                  >
                    {`${id ? 'Редактировать' : 'Добавить'}`}
                  </SubmitBtn>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Wrap>
    </RequestHandler>
  );
};
