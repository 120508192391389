import { gql } from '@apollo/client';

export const REMOVE_APPLICATION_MUTATION = gql`
  mutation removeApplication($id: Int!) {
    removeApplication(id: $id)
  }
`;
export const REMOVE_COUNTRY_MUTATION = gql`
  mutation removeCountry($id: Int!) {
    removeCountry(id: $id)
  }
`;
export const CREATE_COUNTRY_MUTATION = gql`
  mutation createCountry($input: CreateCountryInput!) {
    createCountry(input: $input) {
      id
    }
  }
`;
export const UPDATE_COUNTRY_MUTATION = gql`
  mutation updateCountry($input: UpdateCountryInput!) {
    updateCountry(input: $input) {
      id
    }
  }
`;
