import { createTheme } from '@mui/material';
import fontSizes from './fontSizes';
import fontWeights from './fontWeights';
import palette from './palette';

export const theme = createTheme({
  ...palette,
  ...fontSizes,
  ...fontWeights,

  typography: {
    h4: {
      fontSize: '22px',
      fontWeight: 700,
    },
    error: {
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '13px',
      color: palette.input.error,
    },
  },
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          overflow: 'auto',
          '& ::-webkit-scrollbar': {
            borderRadius: '1px',
            width: '17px',
          },
          '& ::-webkit-scrollbar-thumb': {
            background: '#1976D2 !important',
            borderRadius: '11px',
            border: '5px solid #fff',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'medium',
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            input: {
              fontWeight: 400,
              fontSize: '13px',
              lineHeight: '18px',
              color: palette.input.text,
              padding: 0,
            },
            '.MuiInputBase-root': {
              paddingInlineStart: '14px',
              paddingInlineEnd: '4px',
              minHeight: '38px',
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            input: {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '20px',
              color: palette.input.text,
              padding: 0,
            },
            '.MuiInputBase-root': {
              paddingInlineStart: '14px',
              paddingInlineEnd: '4px',
              minHeight: '44px',
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            input: {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '20px',
              color: palette.input.text,
              padding: 0,
            },
            '.MuiInputBase-root': {
              paddingInlineStart: '14px',
              paddingInlineEnd: '4px',
              minHeight: '50px',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          '& [placeholder]::placeholder': {
            color: palette.input.placeholder,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '20px',
            opacity: 1,
          },
          '.MuiInputBase-root': {
            borderRadius: '6px',
            '.MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.input.border}`,
            },
            ':hover .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.input.outline}`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.input.outline}`,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.error.main}`,
            },
            '& .MuiInputAdornment-root': {
              marginRight: 0,
            },
          },

          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
            {
              WebkitAppearance: 'none',
              margin: '0',
            },
          input: {
            MozAppearance: 'textfield', // FOR MOZILA
          },
          background: '#fff',
        },
      },
    },
  },
});
