import { Box, styled } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import pallete from './../../theme/palette';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: pallete.loginBg,
}));

export const FormWrapper = styled(Box)(() => ({
  width: 400,
  padding: 30,
  borderRadius: 10,
  border: `1px solid ${pallete.black}`,
  backgroundColor: pallete.white,
  height: 300,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const ButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const SubmitBtn = styled(LoadingButton)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
