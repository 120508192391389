import React from 'react';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import { CloseIcon } from '../../Icons';
import { useModalContext } from '../../../context';
import { Flex } from './ModalHead.styled';

export const ModalHead = ({ title }) => {
  const { onCloseModal } = useModalContext();
  return (
    <Flex mb={2}>
      <Typography id='modal-modal-title' variant='h6' component='h2'>
        {title}
      </Typography>
      <IconButton onClick={onCloseModal}>
        <CloseIcon />
      </IconButton>
    </Flex>
  );
};
