import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableList from '../../components/TableList/TableList';
import {
  COUNTRIES_OPTIONS,
  MODAL_NAME,
  PATHS,
} from '../../constants/constants';

import { Wrap } from './Countries.styled';
import { useModalContext } from '../../context';

import { useCountriesQuery, useRemoveCountryMutation } from '../../hooks';
import { toast } from 'react-toastify';

const createData = (country) => {
  const image = country.imagePath;
  return { ...country, image };
};

export const Countries = () => {
  const { onOpenModal } = useModalContext();
  const navigate = useNavigate();

  const [countriesQuery, { loading, data }] = useCountriesQuery();

  const [removeCountryMutation] = useRemoveCountryMutation({
    onCompleted: () => {
      toast('Страна успешно удалена', {
        type: 'success',
      });
      getCountries();
    },
  });

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const handleRemove = (id) => {
    removeCountryMutation({
      variables: {
        id,
      },
    });
  };

  const getCountries = () => {
    countriesQuery({
      variables: {
        options: {
          limit,
          offset: page * limit,
        },
      },
    });
  };

  const rows =
    data?.countries?.rows?.map((country) => createData(country)) || [];
  const count = data?.countries?.count || 0;

  useEffect(() => {
    getCountries();
  }, [limit, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(+event.target.value);
  };

  const handleDelete = (row) => {
    onOpenModal(MODAL_NAME.conditionModal, {
      id: row?.id,
      handelSubmit: () => handleRemove(row.id),
      title: `Удалить страну ${row.title}?`,
    });
  };

  const handleOpen = (row) => {
    navigate(`${PATHS.countries}/${row.id}`);
  };

  const handleCreateOpen = () => {
    navigate(PATHS.addCountry);
  };

  return (
    <Wrap>
      <TableList
        name={'Список країн'}
        isLoading={loading}
        count={count}
        limit={limit}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        headCells={COUNTRIES_OPTIONS}
        rows={rows}
        handleCreateOpen={handleCreateOpen}
        handleDelete={handleDelete}
        handleOpen={handleOpen}
        hasFilter
      />
    </Wrap>
  );
};
