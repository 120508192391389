import { Typography } from '@mui/material';
import { Toolbar } from './TableToolBar.styled';

export const TableToolBar = ({ listName }) => {
  return (
    <Toolbar>
      <Typography variant='h6' id='tableTitle' component='div'>
        {listName}
      </Typography>
    </Toolbar>
  );
};
