import { useMutation } from '@apollo/client';

import {
  CREATE_COUNTRY_MUTATION,
  REMOVE_APPLICATION_MUTATION,
  REMOVE_COUNTRY_MUTATION,
  UPDATE_COUNTRY_MUTATION,
} from '../graphql/mutations';

export const useRemoveApplicationMutation = (options) => {
  return useMutation(REMOVE_APPLICATION_MUTATION, {
    ...options,
  });
};
export const useRemoveCountryMutation = (options) => {
  return useMutation(REMOVE_COUNTRY_MUTATION, {
    ...options,
  });
};
export const useCreateCountryMutation = (options) => {
  return useMutation(CREATE_COUNTRY_MUTATION, {
    ...options,
  });
};
export const useUpdateCountryMutation = (options) => {
  return useMutation(UPDATE_COUNTRY_MUTATION, {
    ...options,
  });
};
