const palette = {
  primary: '#1976d2',
  black: '#000000',
  white: '#ffffff',
  loginBg: '#D3D2D2',
  activeTableBg: '#d0d3e6',

  input: {
    text: '#3D3835',
    placeholder: '#B5B1AE',
    border: '#E3E0DE',
    outline: '#96A0B5',
    label: '#11142D',
    error: '#FF3B30',
  },
  error: { main: '#FF3B30' },
  green300: '#6FCF97',
  gray700: 'rgba(154, 154, 176, 0.55)',

  //toasts
  correct: '#177A00',
  attention2: '#BD7800',
  info: '#0EA7C9',
};

export default palette;
