import { IconButton, styled } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box } from '@mui/system';

export const AddBtn = styled(IconButton)({
  display: 'block',
  marginLeft: 'auto',
});
export const AddBtnIcon = styled(AddCircleIcon)({
  fontSize: 48,
});
export const TitleWrapper = styled(Box)({
  display: 'flex',
});
